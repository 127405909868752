import ReactDOM from 'react-dom';
import { PetscareApp } from './PetscareApp';


ReactDOM.render(
  
  <PetscareApp />,
  document.getElementById('root')
  );

