import { AppRouter } from "./routes/AppRouter"
import './PetscareApp.css';
import './helpers/cubiertaImg.css';


export const PetscareApp = () => {

    return (
        <>
            <AppRouter />
        </>
    )
}